import * as React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarProvider } from "notistack";
import ForgotPassword from "../components/ForgotPassword";

export default function Forgot() {
  const notistackRef = React.createRef();
  const onClickDismissSnackbar = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      ref={notistackRef}
      action={(key) => (
        <IconButton aria-label="close" onClick={onClickDismissSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      )}
    >
      <ForgotPassword />;
    </SnackbarProvider>
  );
}
